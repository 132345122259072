.poi-card {
  margin: 0;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  .poi-image {
    height: 140px;
    background-size: cover;
    background-position: center;
  }

  ion-card-header {
    padding: 2rem;
  }

  ion-card-title {
    font-size: 1rem;
    line-height: 1.2;
  }
}


.guide-welcome-image {
  background-size: cover;
  background-position: center;
  margin: 1.5rem;
  border-radius: 15px;
}

.poi-list {
  padding: 1.5rem;
}

.poi-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  &.disabled-option {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .poi-image {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }
  
  .poi-content {
    flex: 1;
    
    h3 {
      margin: 0 0 0.5rem;
      font-size: 1.1rem;
    }
    
    .duration {
      color: var(--ion-color-medium);
      font-size: 0.9rem;
      margin: 0 0 0.5rem;
    }
    
    .progress-container {
      height: 4px;
      background-color: #eee;
      border-radius: 2px;
      overflow: hidden;
      
      .progress-bar {
        height: 100%;
        transition: width 0.3s ease;
      }
    }
  }
  
  .play-button {
    margin: 0;
    width: 70px;
  }
}
