.poi-detail {
    padding: 1.5rem;
    .image-container {
      position: relative;
      width: 100%;
      margin-bottom: 3rem;
  
      .poi-modal-image {
        width: 100%;
        max-width: 280px;
        margin: auto;
        display: block;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 15px;
      }
  
      .actions-overlay {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 60px;
        border-radius: 30px;
        display: flex;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        justify-content: space-around;
        align-items: center;
        ion-button {
          --color: rgb(255, 255, 255);
          font-size: 0.9rem;
        }
      }
    }
  
    .content-info {
      text-align: center;
      margin-bottom: 0px;
  
      h2 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
  
      p {
        color: var(--ion-color-medium);
        line-height: 1.6;
      }
    }
}

.audio-player {
  padding: 16px;
  margin: 0 auto;
  max-width: 500px;
  
  .controls {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .waveform {
    width: 100%;
    border-radius: 8px;
    padding: 8px;

    wave {
      overflow: hidden;
    }
  }

  .skeleton-loader {
    width: 100%;
    height: 60px;
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
}