.onboarding-page {
  height: 100%;
  padding-top: 1.5rem;

  .onboarding-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;

    .step-indicators {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-bottom: 2rem;

      .step-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--ion-color-medium);

        &.active {
          background-color: var(--ion-color-primary);
        }
      }
    }

    .button-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1.5rem;
      background-color: #ffffff;
      box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
      z-index: 1000;

      ion-button.next {
        min-width: 100px;
      }
    }

  }
  h2 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 0rem;
  }

  p {
    text-align: center;
    color: var(--ion-color-medium);
    margin-bottom: 2rem;
  }

  ion-list {
    margin-bottom: 2rem;
    &::after { 
      content: '';
      display: block;
      height: 80px;
    }
  }

  ion-item {
    --inner-padding-bottom: 15px;

    h2 {
      text-align: left;
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
    }

    p {
      text-align: left;
      font-size: 0.9rem;
      margin: 0;
    }

    &:has(.product-card) {

      ion-label{
        margin: 0 !important;
      }

      ion-radio,
      ion-checkbox{
        width: 0;
        height: 0;
        overflow: hidden;
        margin: 0;
      }
    }


    .product-card {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 14px 16px;
      border-radius: var(--app-border-radius);
      background-color: var(--lt-color-gray-100);
      border: 1px solid var(--lt-color-gray-200);
      transition: background-color 0.3s ease;


      img{
        width: 20%;
        min-width: 100px;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 12px;
        object-fit: contain;
        background-color: var(--lt-color-gray-100);
      }

      .product-card-content{
        flex: 1;
        h2{
          font-size: 1.2rem;
          margin: 0;
          margin-bottom: 0.5rem;
        }

        p{
          font-size: 0.9rem;
          margin: 0;
        }
      }
    }


    &.item-radio-checked{
      .product-card {
        background-color: var(--app-color-primary);
        border-color: var(--app-color-primary);
        h2, p{
          color: #fff;
        }
      }
    }
  }
}