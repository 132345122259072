.generating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1.5rem;
  text-align: center;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: var(--ion-color-medium);
  }

  .progress-circle-container {
    width: 200px;
    height: 200px;
    position: relative;

    .progress-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      font-weight: bold;
      z-index: 1;
    }

    .progress-circle {
      transform: rotate(-90deg);
      width: 100%;
      height: 100%;

      .progress-circle-bg {
        fill: none;
        stroke: #eee;
        stroke-width: 8;
      }

      .progress-circle-path {
        fill: none;
        stroke-width: 8;
        stroke-linecap: round;
        transition: stroke-dasharray 0.1s ease;
      }
    }
  }
} 