.welcome-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  .welcome-background__image-wrapper {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    
  }
}

.welcome-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.welcome-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem;
  z-index: 2;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    max-width: 600px;
    margin-top: 0;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-bottom: 2rem;
    width: 100%;
    align-items: center;

    ion-button {
      max-width: 200px;
      width: 100%;
    }
  }
}

.language-selector {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0 10px;
} 