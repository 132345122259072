.custom-header {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-top: 1rem;
  position: relative;
  background-color: white;
  h1 {
    text-align: center;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 30px;
  }

  .back-button {
    position: absolute;
    left: 2rem;
  }
  
  .settings-button {
    position: absolute;
    right: 2rem;
  }

  .custom-round-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--ion-color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s ease;

    ion-icon {
      font-size: 24px;
      color: var(--ion-color-dark);
    }

    &:active {
      opacity: 0.7;
    }
  }
} 
