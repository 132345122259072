.not-found-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  
  .not-found-content {
    max-width: 400px;
    
    h1 {
      font-size: 6rem;
      margin: 0;
      color: #2fdf75;
    }
    
    h2 {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
    
    p {
      color: #666;
      margin-bottom: 2rem;
    }
  }
} 