.round-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--ion-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ion-icon {
    font-size: 24px;
    color: var(--ion-color-dark);
  }

  &:active {
    opacity: 0.7;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
} 